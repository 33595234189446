import Vue from 'vue'
import Vuex from 'vuex'

import { getSubjectList, getRoadMap } from 'network/home'

Vue.use(Vuex)

export const subject = {
  state: {
    subjectArr: [],
    subjectRouteArr: [
      "/java",
      "/html5",
      "/bigdata",
      "/python",
      "/ui",
      "/wlw",
      "/linux",
      "/softwaretest",
      "/unity",
      "/wlaq",
      "/newMedia",
      "/video-clip",
    ]
  },
  mutations: {},
  actions: {
    async INIT_SUBJECTS(context) {
      const res = await getSubjectList()
      context.state.subjectArr = res.data
      // context.dispatch('GET_ROADMAP')
    },
    /*     async GET_ROADMAP() {
          const res = getRoadMap()
        } */
  },
  modules: {}
}
