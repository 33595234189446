import Vue from 'vue'
import Vuex from 'vuex'

import { getAllArticles } from "network/article";

Vue.use(Vuex)

export const articles = {
    state: {
        allArticleList: [],
    },
    mutations: {},
    actions: {
        async INIT_ARTICLES(context) {
            const res = await getAllArticles();
            context.state.allArticleList = res.data;
        },
    },
    modules: {}
}
