import axios from 'axios'

export function request(options) {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: process.env.VUE_APP_BASEURL,
      timeout: 3000,
    })

    instance.interceptors.response.use(
      (res) => {
        if (res.status === 301) return reject('尚未登录!')
        if (res.status === 401) return reject('错误状态码!')
        if (res.status === 500) return reject('服务器错误!')
        return res.data
      },
      (err) => {
        //超时
        if (err.code === 'ECONNABORTED' && err.message.indexOf('timeout') !== -1) {
          // eslint-disable-next-line
          return reject('请求超时！')
        }
        return reject(err)
      }
    )

    instance(options)
      .then((res) => {
        resolve(res)
      })
      .catch((err) => {
        reject(err)
      })
  })
}
