import Vue from 'vue'
import VueRouter from 'vue-router'

const Home = () => import('views/home/Home')
const Video = () => import('views/video/Video')
const Classify = () => import('views/classify/Classify')
const ArticleList = () => import('views/articleList/ArticleList')
const Article = () => import('views/article/Article')
const Download = () => import('views/download/Download')

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    // name: 'Home',
    component: Home,
    meta: {
      title: 'Java视频教程_大数据视频教程_Python视频教程-海量免费下载_千锋教育',
      content: {
        keywords:
          'Java视频教程,大数据视频教程,Python视频教程,千锋教育',
        description:
          '千锋教育IT视频教程主要包括Java视频教程、大数据视频教程、Python视频教程、Web前端视频教程、UI视频教程、Linux视频教程、unity视频教程、软件测试视频教程、网络安全视频教程、嵌入式物联网视频教程、全媒体视频教程等26大主流IT学习视频教程,海量视频教程免费下载.'
      },
      keepAlive: true
    },
    children: [
      {
        path: '',
        component: () => import('views/home/subjects/Java.vue'),
        meta: {
          title: 'Java视频教程_大数据视频教程_Python视频教程-海量免费下载_千锋教育',
          content: {
            keywords:
              'Java视频教程,大数据视频教程,Python视频教程,千锋教育',
            description:
              '千锋教育IT视频教程主要包括Java视频教程、大数据视频教程、Python视频教程、Web前端视频教程、UI视频教程、Linux视频教程、unity视频教程、软件测试视频教程、网络安全视频教程、嵌入式物联网视频教程、全媒体视频教程等26大主流IT学习视频教程,海量视频教程免费下载.'
          }
        }
      },
      {
        path: '/java',
        name: 'Java',
        component: () => import('views/home/subjects/Java.vue'),
        meta: {
          title: 'Java视频教程_Java教程_Java培训课程_千锋教育',
          content: {
            keywords: 'Java视频教程,Java教程,Java培训课程,千锋教育',
            description:
              '千锋教育Java视频教程从入门到精通(完整版)全部支持免费下载包含全套初级、中级、高级Java视频教程及Java培训课程,Java实例源码,让你全面学习Java,快速掌握Java开发技能.'
          }
        }
      },
      {
        path: '/html5',
        name: 'HTML5',
        component: () => import('views/home/subjects/Web'),
        meta: {
          title: '前端视频教程_html5视频教程_web前端教程_千锋教育',
          content: {
            keywords: '前端视频教程,html5视频教程,web前端教程,千锋教育',
            description:
              '千锋教育前端视频教程从入门到精通全套视频教程免费下载包含全套初级、中级、高级web前端视频教程从服务器、web前端核心、进阶、H5移动开发、框架技术等web前端免费学习视频,适合自学web前端的免费视频平台.'
          }
        }
      },
      {
        path: '/bigdata',
        name: 'Bigdata',
        component: () => import('views/home/subjects/BigData'),
        meta: {
          title: '大数据视频_大数据视频教程_大数据教程_千锋教育',
          content: {
            keywords: '大数据视频,大数据视频教程,大数据教程,千锋教育',
            description:
              '千锋教育大数据视频教程内容涵盖大数据入门视频教程,大数据中级视频教程，大数据高级视频教程以及各种项目实例源码,支持免费下载学习,案例更多,讲解更深入,助您快速成为大数据工程师。'
          }
        }
      },
      {
        path: '/python',
        name: 'Python',
        component: () => import('views/home/subjects/Python'),
        meta: {
          title: 'python视频教程_Python视频_python入门教程_千锋教育',
          content: {
            keywords: 'python视频教程,python视频,python入门教程,千锋教育',
            description:
              '千锋教育Python视频教程,包含全套初级、中级、高级Python视频,全部Python教程完整版免费下载,持续更新,更多Python入门教程让你轻松学会Python开发。'
          }
        }
      },
      {
        path: '/ui',
        name: 'Ui',
        component: () => import('views/home/subjects/Ui'),
        meta: {
          title: 'UI设计教程_UI教程_UI视频教程_千锋教育',
          content: {
            keywords: 'UI设计教程,UI教程,UI视频教程,千锋教育',
            description:
              '千锋教育UI视频教育频道为UI设计师提供原创UI教程、UI视频教程、UI设计教程,UI自学方法等高清优质视频教程,免费提供在线观看下载,让UI设计初学者快速入门，短期内迅速掌握UI设计实战技能.'
          }
        }
      },
      {
        path: '/wlw',
        name: 'Wlw',
        component: () => import('views/home/subjects/Iot'),
        meta: {
          title: '物联网视频_物联网+嵌入式开发教程_千锋教育物联网视频教程',
          content: {
            keywords: '物联网视频,嵌入式开发教程,物联网视频教程,千锋教育',
            description:
              '千锋教育视频资源库提供全套物联网视频教程,支持在线学习及免费下载,帮助更多初学者,快速掌握物联网技术,更有物理网视频教程入门到精通(完整版)全部支持免费下载包含全套初级、中级、高级物联网+嵌入式开发教程视频教程.'
          }
        }
      },
      {
        path: '/linux',
        name: 'Linux',
        component: () => import('views/home/subjects/Linux'),
        meta: {
          title: 'Linux教程_云计算视频教程_云计算入门教程_千锋教育',
          content: {
            keywords: 'Linux教程,云计算视频教程,云计算入门教程,千锋教育',
            description:
              '千锋教育云计算视频教程、Linux教程包含全套初级、中级、高级云计算视频,全部Linux教程完整版免费下载,持续更新,更多云计算入门教程让你轻松学会云计算.'
          }
        }
      },
      {
        path: '/softwaretest',
        name: 'SoftwareTest',
        component: () => import('views/home/subjects/SoftwareTest'),
        meta: {
          title: '软件测试教程_软件测试自学全套教程_千锋教育软件测试视频教程',
          content: {
            keywords: '软件测试教程,软件测试自学全套教程,软件测试视频教程,千锋教育',
            description:
              '千锋全栈软件测试教程频道为广大软件测试爱好者提供软件测试视频教程,软件测试流程和软件测试学习方法,全新软件测试自学全套教程免费领取,让你能够胜任软件测试工程师这一职位.'
          }
        }
      },
      {
        path: '/unity',
        name: 'Unity',
        component: () => import('views/home/subjects/Unity'),
        meta: {
          title: '千锋教育Unity游戏开发视频教程-全套Unity3D视频免费下载',
          content: {
            keywords: 'unity游戏开发视频教程,unity视频,Unity3D视频教程',
            description:
              '千锋教育Unity游戏开发视频教程，由千锋Unity游戏开发培训讲师精心录制，从入门到精通，支持免费下载。'
          }
        }
      },
      {
        path: '/wlaq',
        name: 'Wlaq',
        component: () => import('views/home/subjects/Wlaq'),
        meta: {
          title: '网络安全视频教程_网络安全教程_网络安全课程_千锋教育',
          content: {
            keywords: '网络安全视频教程,网络安全教程,网络安全课程,千锋教育',
            description:
              '千锋教育网络安全教程从入门到精通(完整版)全部支持免费下载包含全套初级、中级、高级网络安全视频教程及网络安全培训课程,让你全面学习网络安全,快速掌握网络安全技能.'
          }
        }
      },
      {
        path: '/php',
        name: 'Php',
        redirect: '/java'
      },
      {
        path: 'newMedia',
        name: 'newMedia',
        component: () => import('views/home/subjects/NewMedia'),
        meta: {
          title: '短视频教程_全媒体运营教程_短视频+直播电商教程_千锋教育',
          content: {
            keywords: '短视频教程,全媒体运营教程,短视频+直播电商教程,千锋教育',
            description:
              '千锋教育短视频教程从入门到精通(完整版)全部支持免费下载包含全套初级、中级、高级全媒体运营视频教程及短视频+直播电商课程,让你全面学习网络安全,快速掌握网络安全技能,全媒体短视频培训+直播电商培训班,2个月集中培训+3年持续帮扶,让0基础小白翻身逆袭.'
          }
        }
      },
      {
        path: 'video-clip',
        name: 'VideoClip',
        component: () => import('views/home/subjects/VideoClip'),
        meta: {
          title: '影视剪辑教程_影视剪辑视频教程_影视剪辑入门教程_千锋教育',
          content: {
            keywords: '影视剪辑教程,影视剪辑视频教程,影视剪辑入门教程,千锋教育',
            description:
              '千锋教育影视剪辑视频教程、影视剪辑教程包含全套初级、中级、高级影视剪辑视频,全部影视剪辑教程完整版免费下载,持续更新,更多影视剪辑入门教程让你轻松学会影视剪辑.'
          }
        }
      },
    ]
  },
  // 视频播放页
  {
    path: '/video/:subjectId/:courseId',
    name: 'Video',
    component: Video,
    meta: {
      title: '千锋视频资源库-Java视频教程海量下载-千锋教育',
      content: {
        keywords:
          'Java视频教程,web前端视频教程,大数据视频教程,UI视频教程,网络安全视频教程,Python视频教程,pmp视频教程',
        description:
          '千锋培训视频频道提供在线IT学习培训视频教程信息，包含Java视频教程,web前端视频教程,大数据视频教程,UI视频教程,PHP视频教程相关内容。了解更多IT培训视频教程尽在千锋培训视频频道!'
      }
    }
  },
  // 技术点分类
  {
    path: '/classify',
    name: 'Classify',
    component: Classify,
    meta: {
      title: '千锋视频资源库-全套IT视频教程海量下载-千锋教育',
      content: {
        keywords:
          'Java视频教程,web前端视频教程,大数据视频教程,UI视频教程,网络安全视频教程,Python视频教程,pmp视频教程',
        description:
          '千锋培训视频频道提供在线IT学习培训视频教程信息，包含Java视频教程,web前端视频教程,大数据视频教程,UI视频教程,PHP视频教程相关内容。了解更多IT培训视频教程尽在千锋培训视频频道!'
      }
    }
  },
  // 文章列表
  {
    path: '/articleList',
    name: 'ArticleList',
    component: ArticleList,
    meta: {
      title: '千锋教育视频库_技术文章_前沿技术交流',
      content: {
        keywords: '技术文章,java技术,python技术,大数据技术文章-千锋教育',
        description: '千锋教育java视频技术文章,web前端技术文章,大数据技术文章汇总'
      }
    }
  },
  // 文章详情
  {
    path: '/article/:id',
    name: 'Article',
    component: Article,
    meta: {
      title: '技术论坛 - 千锋教育视频库',
      content: {
        keywords: '千锋教育技术文章,java技术,python技术,大数据技术文章-千锋教育-IT技术分享',
        description: '千锋教育论坛，提供IT行业最新技术分享，关注最新的前沿的技术信息。'
      }
    }
  },
  // 工具源码
  {
    path: '/download',
    name: 'Download',
    component: Download,
    meta: {
      title: '千锋教育视频资料一站式下载平台-课件、笔记、技术文档、开发工具免费下载',
      content: {
        keywords: 'Java资料工具下载、web前端资料工具下载、大数据资料工具下载、ui资料工具下载',
        description:
          '千锋教育资料一站式下载平台-Java、前端、大数据、Android等技术文档、课件、笔记、jar包、开发工具免费下载'
      }
    }
  },
  // 旧视频站收录文章页重定向
  {
    path: '/course/index/courseId/:id',
    redirect: '/'
  },
  {
    path: '/coursearticle/index/articleId/:id',
    redirect: '/articleList'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

//动态更改标题
router.beforeEach((to, from, next) => {
  window.document.title = to.meta.title
  // document.title = to.matched[0].meta.title

  if (to.meta.content) {
    let head = document.getElementsByTagName('head')
    let meta = document.createElement('meta')
    document
      .querySelector('meta[name="keywords"]')
      .setAttribute('content', to.meta.content.keywords)
    document
      .querySelector('meta[name="description"]')
      .setAttribute('content', to.meta.content.description)
    meta.content = to.meta.content
    head[0].appendChild(meta)
  }

  next()
})

export default router
