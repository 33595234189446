<template>
  <div class="header">
    <div class="head">
      <div class="inner">
        <div class="left">
          <div>
            <a class="logo" :href="'/' + addHrefSearch()">
              <img src="~@/assets/images/logo.png" alt=""
            /></a>
            <a class="name" :href="'/' + addHrefSearch()">千锋视频库</a>
          </div>
        </div>
        <div class="right">
          <ul>
            <li><a :href="homeLink" target="_blank">首页</a></li>
            <li>
              <a
                class="liveVideo"
                rel="”nofollow”"
                target="_blank"
                :href="'http://www.mobiletrain.org/open/' + addHrefSearch()"
              >
                <span></span>直播公开课
              </a>
            </li>
            <li>
              <a :href="'/classify' + addHrefSearch()" target="_blank"
                >技术点分类</a
              >
            </li>
            <li>
              <a :href="'/articleList' + addHrefSearch()" target="_blank"
                >技术文章</a
              >
            </li>
            <li>
              <a :href="'/download' + addHrefSearch()" target="_blank"
                >工具源码</a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TopHeader",
  data() {
    return {};
  },
  computed: {
    homeLink() {
      if (this.$route.query.link === "53") {
        return "http://www.mobiletrain.org/page/it/";
      }
      return "/" + this.addHrefSearch();
    },
  },
  methods: {},
  mounted() {},
  created() {},
};
</script>

<style scoped lang='less'>
.header {
  width: 100%;
  .head {
    background-color: #018dd6;
    height: 66px !important;
    display: flex;
    align-items: center;
    a {
      text-decoration: none;
      color: #ffffff;
      //opacity: 0.7;
    }
    .inner {
      width: 1200px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left {
        div {
          display: flex;
          align-items: center;
          img {
            width: 170px;
          }
          .name {
            opacity: 1;
            border-left: 1px solid;
            padding-left: 12px;
            margin-left: 12px;
            font-weight: bold;
            color: #ffffff;
            font-size: 20px;
            height: 26px;
            line-height: 22px;
          }
          .logo {
            display: flex;
            align-items: center;
            opacity: 1;
          }
        }
      }
      .right {
        ul {
          display: flex;
          justify-content: flex-start;
          li {
            list-style: none;
            font-weight: bold;
            color: #ffffff;
            font-size: 16px;
            cursor: pointer;
            a {
              padding: 24px 16px 25px 15px;
              &.active {
                opacity: 1;
              }
            }
            a:hover {
              padding: 24px 16px 25px 15px;
              background: rgba(255, 255, 255, 0.15);
            }
          }
          .active {
            padding: 24px 16px 25px 15px;
            background: rgba(255, 255, 255, 0.15);
          }
          .liveVideo span {
            margin-top: 1px;
            display: inline-block;
            height: 18px;
            width: 18px;
            vertical-align: top;
            margin-right: 6px;
            background: url(~@/assets/images/navigation_live_courses.png)
              no-repeat;
            background-size: 100% 100%;
          }
        }
      }
    }
  }
}
</style>