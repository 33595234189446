<template>
  <div class="backtop" v-show="isVisible" @click="backtop">
    <i class="el-icon-caret-top"></i>
  </div>
</template>

<script>
import { throttle } from "assets/js/util";

export default {
  name: "BackTop",
  data() {
    return {
      isVisible: false,
    };
  },
  methods: {
    scrollToTop() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      this.isVisible = scrollTop > 300;
    },
    backtop() {
      document.querySelector(".header").scrollIntoView({
        block: "start",
        behavior: "smooth",
      });
    },
  },

  mounted() {
    window.addEventListener("scroll", throttle(this.scrollToTop, 300));
  },
};
</script>

<style scoped lang='less'>
.backtop {
  position: fixed;
  bottom: 60px;
  right: 40px;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.12);
  border-radius: 50%;
  color: #409eff;
  cursor: pointer;
  z-index: 99999990;
  &:hover {
    background-color: #f2f6fc;
  }
  i {
    font-size: 30px;
  }
}
</style>