import { request } from './request'

export function getBanner() {
  return request({
    url: '/api/banner/list'
  })
}

export function getTeachers() {
  return request({
    url: '/api/teacher/home/list'
  })
}

export function getSubjectList() {
  return request({
    url: '/api/subject/list'
  })
}

export function getRoadMap(id) {
  return request({
    url: '/api/subjectstage/list/' + id
  })
}

export function getSourceCodeList() {
  return request({
    url: '/api/soundcode/list'
  })
}
export function getArticleList() {
  return request({
    url: '/api/article/list'
  })
}
