<template>
  <div class="copyright">
    <p class="copyright-p">
      Copyright 2011-2024
      <a href="javascript:;" rel="nofollow" title="千锋互联">北京千锋互联科技有限公司</a>
      <a href="https://beian.miit.gov.cn/" rel="nofollow">
        京ICP备12003911号-3
      </a>
      <a target="_blank"
        href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802035719"
        rel="nofollow" style="
              display: inline-block;
              text-decoration: none;
              height: 20px;
              line-height: 20px;
            "><img src="https://www.embedtrain.org/images_index/beian.png">京公网安备
        11010802030320号</a>
    </p>
    <p>
      千锋教育 运营主体：北京千锋互联科技有限公司
      ，属具备计算机技术培训资质的教育培训机构。
    </p>
  </div>
</template>

<script>
export default {
  name: "Copyright",
  data() {
    return {
    };
  },
  methods: {
  },
  mounted() { },
  created() { },
};
</script>

<style scoped>
.copyright {
  font-size: 14px;
  color: #ffffff;
  text-align: center;
  line-height: 24px;
  padding-bottom: 30px;
  padding-top: 30px;
  background-color: #0030af;
}

p {
  color: #fff;
  opacity: .6;
}

.copyright a {
  font-size: 14px;
  color: #fff;
}
</style>