<template>
  <div class="bottom">
    <div class="seo-link">
      <a
        :href="'http://video.mobiletrain.org' + subjectRouteArr[idx] + '/'"
        v-for="(item, idx) in subjectArr"
        target="_blank"
      >
        {{ item.subjectName }}视频教程
      </a>
    </div>
    <friend-links :isShow="isShowFriendLinks" />
    <bottom-foot />
    <copyright @toggleFriendLinks="isShowFriendLinks = !isShowFriendLinks" />
  </div>
</template>

<script>
import FriendLinks from "./childComps/FriendLinks";
import BottomFoot from "./childComps/BottomFoot";
import Copyright from "./childComps/Copyright";
import { mapState } from "vuex";

export default {
  name: "Bottom",
  computed: {
    ...mapState({
      subjectRouteArr: (store) => store.sj.subjectRouteArr,
      subjectArr: (store) => store.sj.subjectArr,
    }),
  },
  components: {
    FriendLinks,
    BottomFoot,
    Copyright,
  },
  data() {
    return {
      isShowFriendLinks: false,
    };
  },
  methods: {},
};
</script>

<style scoped lang="less">
.seo-link {
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  width: 1200px;
  margin: 20px auto;
  // justify-content: center;
  background-color: #fff;
  padding: 20px 0 10px 20px;
  a {
    margin-right: 20px;
    margin-bottom: 12px;
    line-height: 30px;
    color: rgb(59, 59, 59);
    // color: #fff;
    padding: 0px 8px;
    background-color: #cee3ff;
    border-radius: 6px;
    transition: all ease 0.1s;
    font-size: 14px;

    &:hover {
      background-color: #a0c6f8;
    }
  }
}
</style>