<template>
  <div class="bottom_part1">
    <div class="base">
      <h6 class="qiye">
        <span title="友情链接" class="tab_current">友情链接</span>
        <!-- <button class="bottom_btn" @click="isShowMore = !isShowMore">
          更多
          <img
            src="http://img.mobiletrain.org/templates/mobiletrain/images/index_arrow.png" />
        </button> -->
      </h6>
      <div class="btn_content">
        <p>
          <a href="http://www.hixcgj.com/" target="_blank">alevel培训</a>
          <a href="http://robot.jiameng.com" target="_blank">编程加盟 </a>
          <a href="http://kuakao.tantuw.com" target="_blank">跨考考研</a>
          <a href="http://xuefu.tantuw.com/ " target="_blank">学府考研</a>
          <a href="https://guojishuoshi.eduego.com/" target="_blank">国际硕士</a>
          <a href="https://fuzhou.huatu.com/" target="_blank">福州事业单位招聘</a>
          <a href="https://zb.zhaobiao.cn/  " target="_blank">招标频道</a>
          友链申请（qq）：263370632
        </p>
        <!-- <p :style="{ display: isShowMore ? 'block' : 'none' }">
         
          友链申请（qq）：263370632
        </p> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FriendLinks",
  data() {
    return {
      isShowMore: false,
    };
  },
  methods: {},
};
</script>

<style scoped lang='less'>
.bottom_part1 {
  background: #ffffff;
  padding-top: 30px;
  padding-bottom: 30px;

  //默认tab样式
  .base .qiye span {
    color: #444;
  }

  //添加tab current样式
  .base .qiye .tab_current {
    color: #fff;
    background-color: #3688ff;
  }
}

.bottom_part1 .btn_content p:nth-child(1) {
  clear: both;
}

.bottom_part1 .btn_content p:nth-child(1) span {
  display: inline-block;
  width: 156px;
  height: 58px;
  border: 1px solid #bfbfbf;
  margin-right: 10px;
  margin-bottom: 12px;
}

.bottom_part1 .btn_content p:nth-child(1) span:nth-child(7),
.bottom_part1 .btn_content p:nth-child(1) span:nth-child(14),
.bottom_part1 .btn_content p:nth-child(1) span:nth-child(21) {
  margin-right: 0;
}

.bottom_part1 .btn_content p:nth-child(2) {
  display: none;
}

.bottom_part1 .btn_content p a {
  display: inline-block;
  padding: 0px 24.5px 26px 0;
  color: #444;
  font-size: 16px;
}

.bottom_part1 .btn_content p a:hover {
  color: #f74c4c;
}

.bottom_part1 .bottom_btn {
  position: relative;
  display: block;
  font-size: 16px;
  border: none;
  line-height: 46px;
  background-color: #eaeeee;
  border: 0;
  outline: none;
  cursor: pointer;
}

.bottom_part1 .bottom_btn img {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 13px;
}

.base {
  margin: 0 auto;
  width: 1200px;
}

.qiye {
  background: #eaeeee;
  margin-bottom: 30px;
  height: 46px;
  overflow: hidden;
}

.qiye span {
  float: left;
  display: inline-block;
  width: 120px;
  height: 46px;
  font-size: 18px;
  text-align: center;
  margin-right: 15px;
  line-height: 46px;
  font-weight: 100;
  color: #fff;
}

.yqlj_wrap {
  padding-bottom: 20px;
  padding-top: 20px;
  background: #fff;
}

.yqlj_tab {
  display: flex;
  height: 45px;
  line-height: 45px;
  background: #eaeeee;
  margin-bottom: 15px;
}

.yqlj_tab span {
  display: inline-block;
  cursor: pointer;
  width: 120px;
  font-size: 18px;
  text-align: center;
}

.yqlj_tab span.on {
  background: #3688ff;
  color: #fff;
}

.yqlj_content a {
  display: block;
  float: left;
  margin-right: 11px;
}

.con12_content a:hover {
  color: #3688ff;
}

.yqlj_content a:nth-last-child(1) {
  margin-right: 0;
}

.yqlj_content p {
  display: inline-block;
}

.yqlj_content p:nth-child(6n) {
  margin-right: 0;
}

.yqlj_content a {
  font-size: 14px;
  color: #666;
  cursor: pointer;
  border-right: 1px solid #666666;
  padding-right: 14px;
  margin-right: 14px;
  margin-bottom: 14px;
  padding-left: 14px;
}

.yqlj_content li:nth-child(2) {
  display: none;
}

.yqlj_wrap .bottom_btn {
  position: relative;
  display: block;
  font-size: 16px;
  border: none;
  line-height: 46px;
  background-color: #eaeeee;
  border: 0;
  outline: none;
  margin-left: 14px;
}

.yqlj_wrap .bottom_btn img {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 13px;
  display: inline;
}
</style>