import Vue from 'vue'
import 'babel-polyfill'
import Vuex from 'vuex'

import { subject } from './subject'
import { articles } from './article'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {},
  actions: {
    PAGE_INIT(context) {
      context.dispatch('INIT_SUBJECTS')
      // context.dispatch('INIT_ARTICLES')
    }
  },
  modules: {
    sj: subject,
    ar: articles
  }
})
