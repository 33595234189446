<template>
	<div class="edu-footer">
		<div class="base">
			<div class="con-left">
				<img src="https://www.embedtrain.org/images/new-logo-white.png" alt="" class="edu-footer-logo" />
				<p class="edu-footer-sologan">初心至善&nbsp;&nbsp;匠心育人</p>
				<p class="edu-footer-phoneNumber">
					<i class="icon icon-dianhua1"></i>400-811-9990
					<a href="javascript:;" onclick="open53_pc()" rel="nofollow">24小时在线咨询</a>
				</p>
			</div>
			<div class="con-right">
				<div class="edu-footer-about-links">
					<div class="list1 list">
						<h4>关于千锋</h4>
						<ul>
							<li>
								<a href="http://www.mobiletrain.org/about/" target="_blank">千锋简介</a>
							</li>
							<li>
								<a href="http://www.mobiletrain.org/about/contact.html" target="_blank">联系我们</a>
							</li>
							<li>
								<a href="http://www.mobiletrain.org/qynx/" target="_blank">企业服务</a>
							</li>
							<li>
								<a href="http://www.mobiletrain.org/topic/fygy.html" target="_blank">锋益公益</a>
							</li>
						</ul>
					</div>
					<div class="list1 list">
						<h4>学习资源</h4>
						<ul>
							<li>
								<a href="http://prj.qfedu.com/" target="_blank" rel="nofollow">项目库</a>
							</li>
							<li>
								<a href="http://www.mobiletrain.org/open/" target="_blank">公开课</a>
							</li>
							<li>
								<a rel="nofollow" href="http://video.mobiletrain.org/" target="_blank">视频教程</a>
							</li>
							<li>
								<a href="http://www.mobiletrain.org/book/index.html?type=1" target="_blank">原创丛书</a>
							</li>
						</ul>
					</div>
					<div class="list1 list">
						<h4>服务指南</h4>
						<ul>
							<li>
								<a href="javascript:;" onclick="open53_pc()" rel="nofollow">报名咨询</a>
							</li>
							<li>
								<a href="javascript:;" onclick="open53_pc()" rel="nofollow">校区分布</a>
							</li>
							<li>
								<a href="http://www.mobiletrain.org/sitemap.html" target="_blank">网站地图</a>
							</li>
							<li>
								<a href="http://www.mobiletrain.org/privacyPolicy.html" target="_blank">隐私声明</a>
							</li>
						</ul>
					</div>
				</div>
				<div class="qrcode-box">
					<div class="qrcode flex-center">
						<img src="https://www.embedtrain.org/images/qrcode/qrcode.png" alt="千锋教育" />
					</div>
					<h4>千锋学习站 | <span>随时随地免费学</span></h4>
				</div>
				<div class="qrcode-box">
					<div class="qrcode flex-center">
						<img src="https://www.embedtrain.org/images/qr-code-new2.png" alt="千锋教育" />
					</div>
					<h4>扫一扫进入千锋手机站</h4>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "BottomFoot",
	data() {
		return {};
	},
	methods: {},
};
</script>

<style scoped>
/*bottom*/
.edu-footer {
	padding-top: 60px;
	background: #0467fb url(/wzt/foot-bg.png) no-repeat;
	background-size: 100% 100%;
	box-sizing: border-box;
	position: relative;
}

.edu-footer .base {
	display: flex;
	height: 210px;
}

.edu-footer .base .con-left {
	width: 306px;
	border-right: 1px solid rgba(255, 255, 255, 0.2);
	color: #fff;
	height: 178px;
}

.edu-footer .base .con-left .edu-footer-logo {
	width: 221px;
}

.edu-footer .base .con-left .edu-footer-sologan {
	margin: 12px 0 26px 0;
	font-size: 16px;
	line-height: 16px;
	letter-spacing: 0.5em;
}

.edu-footer .base .con-left .edu-footer-phoneNumber {
	font-size: 25px;
	height: 23px;
	line-height: 23px;
}

.edu-footer .base .con-left .edu-footer-phoneNumber .icon {
	font-size: 26px;
	margin-right: 8px;
}

.edu-footer .base .con-left .edu-footer-phoneNumber a {
	width: 130px;
	height: 30px;
	border-radius: 4px;
	border: solid 1px #ffffff;
	display: block;
	color: #ffffff;
	font-size: 14px;
	line-height: 30px;
	text-align: center;
	margin-left: 36px;
	margin-top: 10px;
}

.edu-footer .base .con-left .edu-footer-phoneNumber a:hover {
	background: #fff;
	color: #326cf6;
}

.edu-footer .base .con-right {
	width: 803px;
	display: flex;
	padding-left: 67px;
}

.edu-footer .base .con-right .edu-footer-about-links {
	display: flex;
}

.edu-footer .base .con-right .edu-footer-about-links .list {
	margin-right: 65px;
	text-align: center;
}

.edu-footer .base .con-right .edu-footer-about-links .list:last-of-type {
	margin-right: 58px;
}

.edu-footer .base .con-right .edu-footer-about-links .list h4 {
	margin-bottom: 30px;
	line-height: 20px;
	font-size: 20px;
	font-weight: 600;
	color: #ffffff;
	white-space: nowrap;
}

.edu-footer .base .con-right .edu-footer-about-links .list ul li {
	height: 31px;
	line-height: 31px;
}

.edu-footer .base .con-right .edu-footer-about-links .list ul li a {
	color: #fff;
	font-size: 14px;
}

.edu-footer .base .con-right .edu-footer-about-links .list ul li a:hover {
	text-decoration: underline;
}

.edu-footer .base .con-right .qrcode-box {
	width: 191px;
	color: #fff;
	text-align: center;
}

.edu-footer .base .con-right .qrcode-box + .qrcode-box {
	margin-left: 26px;
}

.edu-footer .base .con-right .qrcode-box .qrcode {
	margin: auto;
	width: 135px;
	height: 135px;
	background: #ffffff;
	border-radius: 30px;
	box-sizing: border-box;
	display: flex;
	justify-content: center;
	align-items: center;
}

.edu-footer .base .con-right .qrcode-box .qrcode img {
	width: 118px;
	height: 118px;
}

.edu-footer .base .con-right .qrcode-box h4 {
	white-space: nowrap;
	margin: 16px 0 7px 0;
	line-height: 16px;
	font-size: 14px;
	font-weight: normal;
	color: #ffffff;
}

.edu-footer .base .con-right .qrcode-box h4 span {
	font-size: 14px;
}

.edu-footer .base .con-right .qrcode-box p {
	font-size: 14px;
}
</style>
