import { request } from './request'

export function getAllArticles() {
  return request({
    url: '/api/article/list/all'
  })
}

export function getArticle(id) {
  return request({
    url: `/api/article/info/${id}`
  })
}

