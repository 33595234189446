export function throttle(fn, delay = 300) {
  let timeout = null
  return function () {
    if (timeout) return
    timeout = setTimeout((...args) => {
      fn.apply(this, args)
      timeout = null
    }, delay)
  }
}
