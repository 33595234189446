<template>
  <div id="app" class="app">
    <top-header />
    <keep-alive exclude="Classify,Video,Article">
      <router-view />
    </keep-alive>
    <bottom />

    <back-top></back-top>
  </div>
</template>

<script>
import TopHeader from "common/TopHeader";
import Bottom from "common/Bottom";
import BackTop from 'components/BackTop'

export default {
  name: "App",
    components: {
    TopHeader,
    Bottom,
    BackTop
  },
  computed:{
    
  },
  data() {
    return {
    };
  },
  methods: {
  },
  mounted() {

  },
  created() {
    this.$store.dispatch("INIT_SUBJECTS");
  },
};
</script>

<style>
#app {
  width: 100%;
  /* height: 100vh; */
  background: #f4f4f4;
  /* overflow-y: scroll; */
  /* overflow-x: hidden; */
}

</style>
