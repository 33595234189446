import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'assets/css/common.css'
import 'babel-polyfill'
// 剪贴板第三方库
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

import { Avatar, Tag, Icon, Dialog, Tabs, TabPane, Pagination, Button, Backtop, Empty } from 'element-ui'

Vue.use(Avatar)
Vue.use(Tag)
Vue.use(Icon)
Vue.use(Dialog)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Pagination)
Vue.use(Button)
Vue.use(Backtop)
Vue.use(Empty)

Vue.config.productionTip = false

// 全局会用到的方法
Vue.prototype.removeHTMLTag = function (str) {
  str = str.replace(/(\n)/g, '') //过滤回车符
  str = str.replace(/(\t)/g, '') //过滤制表符
  str = str.replace(/(\r)/g, '') //过滤换行符
  str = str.replace(/\s*/g, '') //过滤空白
  str = str.replace(/&nbsp;/, '')
  str = str.replace(/<\/?.+?\/?>/g, '') //过滤&nbsp
  return str
}

Vue.prototype.addHrefSearch = function () {
  if (!/\?./.test(location.search)) return ''
  let hash = location.search.substring(1)
  let paramsArr = hash.split('&').filter((item) => item)
  let add = ''
  for (let i = 0; i < paramsArr.length; i++) {
    const param = paramsArr[i].split('=')[0]
    if (!param.match(/courseId|subjectId|articleId|labelId/g)) add += `&${paramsArr[i]}`
  }
  return '?' + add.substring(1)
}

Vue.prototype.formatLevel = function (level) {
  const rt = ['入门', '中级', '高级', '项目', '面试']
  return rt[level - 1] || ''
}

new Vue({
  router,
  store,
  render: (h) => h(App),
  mounted() {
    document.dispatchEvent(new Event('render-event'))
  }
}).$mount('#app')
